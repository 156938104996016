/**
 * Inhaltsverzeichnis
 * 	1. 
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ==================================================
// MARK: 0. Setup
// --------------------------------------------------
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import { CSSRuleSet, CSSStyleSheetManager } from '../../../ts/class/style/exportlist';
import stylesheet from './style.scss?inline';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#


// ==================================================
// MARK: 1. 
// --------------------------------------------------
export default class PanelHeadElement extends HTMLElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #==== Static ====#
	/** An Interface for all contained styles statically */
	static readonly _styles:CSSRuleSet		=	new CSSRuleSet(stylesheet);


		// #==== Uninitialized ====#


		// #==== Initialized ====#
	/**	Shadow DOM of the Element */
	public shadowRoot				=	this.attachShadow({
		mode: 'open',
		slotAssignment: 'manual'
	});
	/** An Interface for all contained styles localy */
	readonly _styles:CSSRuleSet		=	new CSSRuleSet();
	/**	Object containing the Ruleset for the Host Element */
	protected _ruleset:CSSStyleSheetManager		=	new CSSStyleSheetManager({
		static: PanelHeadElement._styles,
		element: this._styles
	});


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor(
		
	) {
		// #==== Parent ====#
		super();


		// #==== Properties ====#
		this.shadowRoot.adoptedStyleSheets		=	this._ruleset.list();


		// #==== Actions ====#
		this.render();

	}


	// #=#=#=#=# 1.3 Build #=#=#=#=#=#
	/**
	 * Builds the ShadowRoot HTML of the element.
	 */
	private render(
	): void {
		this.shadowRoot.innerHTML = `
			<slot name="indicator">
				<cvh-icon character="f107"></cvh-icon>
			</slot>
			<div>
				<slot>Title</slot>
			</div>
		`;
	}
}


// ==================================================
// MARK: 2. Initialisation
// --------------------------------------------------
window.customElements.define('cvh-panel-head', PanelHeadElement);