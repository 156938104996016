/**
 * Inhaltsverzeichnis
 * 	1. 
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ==================================================
// MARK: 0. Setup
// --------------------------------------------------
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import { CSSRuleSet, CSSStyleSheetManager } from '../../../ts/class/style/exportlist';
import PanelHeadElement from '../head/class';
import PanelBodyElement from '../body/class';
import stylesheet from './style.scss?inline';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#


// ==================================================
// MARK: 1. 
// --------------------------------------------------
export default class PanelElement extends HTMLElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #==== Static ====#
	/** An Interface for all contained styles statically */
	static readonly _styles:CSSRuleSet		=	new CSSRuleSet(stylesheet);
	/**	Observed Attribuges */
	static readonly observedAttributes		=	["open"];


		// #==== Uninitialized ====#


		// #==== Initialized ====#
	/** Child Element Observer */
	private _observer:MutationObserver	=	new MutationObserver(this.assignSlots.bind(this));
	/**	Shadow DOM of the Element */
	public shadowRoot				=	this.attachShadow({
		mode: 'open',
		slotAssignment: 'manual'
	});
	/** An Interface for all contained styles localy */
	readonly _styles:CSSRuleSet		=	new CSSRuleSet();
	/**	Object containing the Ruleset for the Host Element */
	protected _ruleset:CSSStyleSheetManager		=	new CSSStyleSheetManager({
		static: PanelElement._styles,
		element: this._styles
	});
	/**	Contained Slots */
	private _slots!:{head: HTMLSlotElement, body: HTMLSlotElement};


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor(
		
	) {
		// #==== Parent ====#
		super();


		// #==== Properties ====#
		this.shadowRoot.adoptedStyleSheets		=	this._ruleset.list();


		// #==== Actions ====#
		this._observer.observe(this, {
			childList: true
		});
		this.render();
		this.getSlots();

		this.shadowRoot.querySelector('button')?.addEventListener('click', () => {
			this.open();
		});
	}


	// #=#=#=#=# 1.3 Build #=#=#=#=#=#
	/**
	 * Builds the ShadowRoot HTML of the element.
	 */
	private render(
	): void {
		this.shadowRoot.innerHTML = `
			<button>
				<slot part="head"></slot>
			</button>
			<div class="collapsible">
				<slot part="body"></slot>
			</div>
		`;
	}


	// #=#=#=#=#=# 1.? Get Slots #=#=#=#=#=#
	/**
	 * Get the slots of the element.
	 */
	private getSlots(
	): void {
		this._slots	=	{
			head: this.shadowRoot.querySelector('slot[part="head"]') as HTMLSlotElement,
			body: this.shadowRoot.querySelector('slot[part="body"]') as HTMLSlotElement
		};
	}


	// #=#=#=#=#=# 1.? Attribute Changed #=#=#=#=#=#
	/**
	 * Handle the change of attributes.
	 */
	attributeChangedCallback(
		name:string,
		_:string,
		newValue:string
	) {
		switch (name) {
			case 'open':
				if (newValue === null) {
					this.dispatchEvent(new CustomEvent('close'));
				} else {
					this.dispatchEvent(new CustomEvent('open'));
				}
				break;
		}
	}


	// #=#=#=#=#=# 1.? Element Mounted #=#=#=#=#=#
	/**
	 * 
	 */
	connectedCallback(
	): void {
		this.assignSlots();
	}


	// #=#=#=#=#=# 1.? Slot Asignment #=#=#=#=#=#
	/**
	 * Manually assign the slots.
	 */
	private assignSlots(): void {
		// #==== Prepare Storage ====#
		const headNodes:PanelBodyElement[]	=	[];
		const bodyNodes:PanelHeadElement[]	=	[];


		// #==== Collect Nodes ====#
		for(const node of this.children) {
			if(node instanceof PanelHeadElement) {
				headNodes.push(node);
			}
	
			else if(node instanceof PanelBodyElement) {
				bodyNodes.push(node);
			}
		}


		// #==== Assign Slots ====#
		this._slots.head.assign(...headNodes);
		this._slots.body.assign(...bodyNodes);
	}


	// #=#=#=#=#=# 1.? Open #=#=#=#=#=#
	/**
	 * Open the panel.
	 */
	open(
		state?:boolean
	): boolean {
		switch (state) {
			case true:
				this.setAttribute('open', '');
				break;
			case false:
				this.removeAttribute('open');
				break;
			default:
				this.toggleAttribute('open');
				break;
		}

		return this.isOpen;
	}


	// #=#=#=#=#=# 1.? is Open #=#=#=#=#=#
	/**
	 * Check if the panel is open.
	 */
	public get isOpen(): boolean {
		return this.hasAttribute('open');
	}
}


// ==================================================
// MARK: 2. Initialisation
// --------------------------------------------------
window.customElements.define('cvh-panel', PanelElement);