/**
 * Inhaltsverzeichnis
 * 	1. 
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ==================================================
// MARK: 0. Setup
// --------------------------------------------------
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import { CSSRuleSet, CSSStyleSheetManager } from '../../../ts/class/style/exportlist';
import stylesheet from './style.scss?inline';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#


// ==================================================
// MARK: 1. 
// --------------------------------------------------
export default class PanelBodyElement extends HTMLElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #==== Static ====#


		// #==== Uninitialized ====#


		// #==== Initialized ====#



	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor(
		
	) {
		// #==== Parent ====#
		super();


		// #==== Properties ====#
		


		// #==== Actions ====#
		

	}
}


// ==================================================
// MARK: 2. Initialisation
// --------------------------------------------------
window.customElements.define('cvh-panel-body', PanelBodyElement);