import CSSRuleSet from './ruleset.js';
export default class CSSStyleSheetManager {
    collection = new Map();
    constructor(stylesheets) {
        if (!stylesheets) {
            return;
        }
        for (const [slug, content] of Object.entries(stylesheets)) {
            this.set(slug, content);
        }
    }
    set(slug, stylesheet) {
        if (stylesheet instanceof CSSRuleSet) {
            this.collection.set(slug, stylesheet);
            return;
        }
        else {
            this.collection.set(slug, new CSSRuleSet(stylesheet));
        }
    }
    get(slug) {
        return this.collection.get(slug);
    }
    remove(slug) {
        this.collection.delete(slug);
    }
    list() {
        const list = [];
        for (const ruleset of this.collection.values()) {
            list.push(ruleset.getStylesheet());
        }
        return list;
    }
}
