/**
 * Inhaltsverzeichnis
 * 	1. 
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ==================================================
// MARK: 0. Setup
// --------------------------------------------------
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import { CSSRuleSet, CSSStyleSheetManager } from '../../../ts/class/style/exportlist';
import PanelElement from '../item/class';
import stylesheet from './style.scss?inline';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#


// ==================================================
// MARK: 1. 
// --------------------------------------------------
export default class PanelListElement extends HTMLElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #==== Static ====#
	static	readonly	_styles:CSSRuleSet		=	new CSSRuleSet(stylesheet);
	/**	List groups */
	static	readonly	groups:Map<string, Set<PanelListElement>>	=	new Map();
	/**	Observed Attribuges */
	static	readonly	observedAttributes		=	["group", "auto-close"];


		// #==== Uninitialized ====#
	/**	Group Name */
	private	group?:string;


		// #==== Initialized ====#
	/**	Determin if the element should auto close all its children when another is opened */
	private	autoClose:boolean|string	= false;



	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor(
		
	) {
		// #==== Parent ====#
		super();


		// #==== Properties ====#
		


		// #==== Actions ====#
		document.addEventListener('DOMContentLoaded', this.autoOpenByLocation.bind(this));
	}


	// #=#=#=#=#=# 1.? Attributes Changed #=#=#=#=#=#
	attributeChangedCallback(
		name:string,
		oldValue:string,
		newValue:string
	) {
		
	}


	// #=#=#=#=#=# 1.? Element Mounted #=#=#=#=#=#
	/**
	 * Called when the element is mounted to the DOM.
	 */
	connectedCallback(
	): void {
		
	}


	// #=#=#=#=#=# 1.? Auto open by current location #=#=#=#=#=#
	/**
	 * Open the corresponding panel by the current window location hash.
	 */
	private autoOpenByLocation(
	): void {
		const hash	=	window.location.hash.substring(1);
		if (hash) {
			const element	=	this.querySelector(`#${hash}`);
			console.log(element);
			if (element) {
				(element.closest('cvh-panel-item') as PanelElement|null)?.open();
			}
		}
	}
}


// ==================================================
// MARK: 2. Initialisation
// --------------------------------------------------
window.customElements.define('cvh-panel-list', PanelListElement);